import React from 'react'
import './knowledge.style.scss'
import plance2 from 'src/assets/images/plance-2.png'
function Knowledge() {
  return (
    <div className="knowledge scroll-section hidden" id="skill-nav">
      <div className="knowledge__left">
        <div className="knowledge__left--title">
          Curious about my coding language toolbox?
          <br />
          {/* <span>Click show full</span> */}
        </div>

        <div className="knowledge__left--content">
          <div className="knowledge__left--content_item">NodeJS</div>
          <div className="knowledge__left--content_item">NestJS</div>
          <div className="knowledge__left--content_item">ExpressJS</div>
          <div className="knowledge__left--content_item">TypeScript</div>
          <div className="knowledge__left--content_item">HTML</div>
          <div className="knowledge__left--content_item">CSS/SCSS</div>
          <div className="knowledge__left--content_item">ReactJS</div>
          <div className="knowledge__left--content_item">TailwindCSS</div>
          <div className="knowledge__left--content_item">MUI</div>
          <div className="knowledge__left--content_item">Kafka</div>
          <div className="knowledge__left--content_item">Redis</div>
          <div className="knowledge__left--content_item">Minio</div>
          <div className="knowledge__left--content_item">Elasticsearch</div>
          <div className="knowledge__left--content_item">gRPC</div>
          <div className="knowledge__left--content_item">APISIX</div>
          <div className="knowledge__left--content_item">MSSQL</div>
          <div className="knowledge__left--content_item">Postgres</div>
          <div className="knowledge__left--content_item">Cassandra</div>
          <div className="knowledge__left--content_item">PM2</div>
          <div className="knowledge__left--content_item">Nginx</div>
          <div className="knowledge__left--content_item">CentOS</div>
          <div className="knowledge__left--content_item">Ubuntu</div>
          <div className="knowledge__left--content_item">Docker</div>
          <div className="knowledge__left--content_item">SocketIO</div>
        </div>
      </div>
      <div className="knowledge__right">
        <img src={plance2} alt="plance " />
      </div>
    </div>
  )
}

export default Knowledge

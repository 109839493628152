import React from 'react'
import './experience.style.scss'
import plance3 from 'src/assets/images/plance-3.png'
function Experience() {
  return (
    <div className="experience scroll-section hidden" id="experience-nav">
      <div className="experience__left">
        <div className="experience__left--image">
          <img src={plance3} alt="plance" />
        </div>
      </div>
      <div className="experience__right">
        <div className="experience__right--title">Experience</div>
        <div className="experience__right--content">
          <div className="experience__right--content_item">
            <div className="experience__right--content_item-left">
              <svg
                className="line-1 svg1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 83.28 506.81"
              >
                <polyline
                  className="cls-1"
                  points="56.62 13 74.62 77 0.62 206 82.62 290 2.62 395 56.84 496.93"
                />
                <path
                  className="cls-2"
                  d="M55.5.5c12.12,0,19.17,9.2,13.8,13s-9.2,6.91-13,6.14-13.8-1.54-13-6.14S50.13.5,55.5.5Z"
                />
                <path
                  className="cls-2"
                  d="M54.36,487c12.12,0,19.17,9.2,13.8,13s-9.2,6.9-13,6.14-13.8-1.54-13-6.14S49,487,54.36,487Z"
                />
              </svg>
            </div>
            <div className="experience__right--content_item-right">
              <div>
                <section className="experience__right--content_item-right_title">
                  Beta Securities Incorporation.
                </section>
                <section className="experience__right--content_item-right_work">
                  Backend Developer (06/2023 - Now)
                </section>
                <br />
                <ul>
                  <li>
                    <article>Main responsibilities:</article>
                  </li>
                  <li>- Developed scalable RESTful APIs using NestJS about stock market, report to customer daily stock information</li>
                  <li>
                    - Researching, planning and building new features for
                    website.
                  </li>
                  <li>- Optimized database queries and introduced indexing techniques, reducing response time</li>
                  <li>- Maintain and optimizing old code system.</li>
                  <br />
                </ul>

                <section className="experience__right--content_item-right_title">
                  Overate-VNTech
                </section>
                <section className="experience__right--content_item-right_work">
                  Backend Developer (05/2022 - 06/2023)
                </section>
                <br />
                <ul>
                  <li>
                    <article>Main responsibilities:</article>
                  </li>
                  <li>- Developed scalable RESTful APIs using NestJS for social media about F&B</li>
                  <li>
                    - Designing and implementing event-driven microservices architecture using Apache Kafka, ensuring scalability and fault tolerance.
                  </li>
                  <li>- Collaborated with the frontend team to implement real-time data synchronization using WebSocket protocol</li>
                  <li>- Maintain and optimizing old code system.</li>
                  <br />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience

export const menu = [
  {
    section: 'header-nav',
    svg: (
      <svg x="0px" y="0px" viewBox="0 0 26.39 26.39">
        <g>
          <g id="c14_house">
            <path
              d="M3.588,24.297c0,0-0.024,0.59,0.553,0.59c0.718,0,6.652-0.008,6.652-0.008l0.01-5.451c0,0-0.094-0.898,0.777-0.898h2.761
c1.031,0,0.968,0.898,0.968,0.898l-0.012,5.434c0,0,5.628,0,6.512,0c0.732,0,0.699-0.734,0.699-0.734V14.076L13.33,5.913
l-9.742,8.164C3.588,14.077,3.588,24.297,3.588,24.297z"
            />
            <path d="M0,13.317c0,0,0.826,1.524,2.631,0l10.781-9.121l10.107,9.064c2.088,1.506,2.871,0,2.871,0L13.412,1.504L0,13.317z" />
            <polygon points="23.273,4.175 20.674,4.175 20.685,7.328 23.273,9.525 		" />
          </g>
          <g id="Capa_1_216_"></g>
        </g>
      </svg>
    )
  },
  {
    section: 'about-nav',
    svg: (
      <svg x="0px" y="0px" viewBox="0 0 60 60">
        <path
          d="M48.014,42.889l-9.553-4.776C37.56,37.662,37,36.756,37,35.748v-3.381c0.229-0.28,0.47-0.599,0.719-0.951
c1.239-1.75,2.232-3.698,2.954-5.799C42.084,24.97,43,23.575,43,22v-4c0-0.963-0.36-1.896-1-2.625v-5.319
c0.056-0.55,0.276-3.824-2.092-6.525C37.854,1.188,34.521,0,30,0s-7.854,1.188-9.908,3.53C17.724,6.231,17.944,9.506,18,10.056
v5.319c-0.64,0.729-1,1.662-1,2.625v4c0,1.217,0.553,2.352,1.497,3.109c0.916,3.627,2.833,6.36,3.503,7.237v3.309
c0,0.968-0.528,1.856-1.377,2.32l-8.921,4.866C8.801,44.424,7,47.458,7,50.762V54c0,4.746,15.045,6,23,6s23-1.254,23-6v-3.043
C53,47.519,51.089,44.427,48.014,42.889z"
        />
      </svg>
    )
  },
  {
    section: 'skill-nav',
    svg: (
      <svg x="0px" y="0px" viewBox="0 0 512 512">
        <path
          d="M160,384h256c17.672,0,32-14.326,32-32V32c0-17.674-14.328-32-32-32h-64v128l-48-32l-48,32V0H128C92.656,0,64,28.652,64,64
v320v32c0,52.938,43.063,96,96,96h256c17.672,0,32-14.328,32-32s-14.328-32-32-32H160c-17.648,0-32-14.352-32-32
S142.352,384,160,384z"
        />
      </svg>
    )
  },
  {
    section: 'experience-nav',
    svg: (
      <svg viewBox="0 0 512 512">
        <path d="M452,120h-76C376,53.726,322.274,0,256,0h0c-66.274,0-120,53.726-120,120H60c-33.137,0-60,26.863-60,60v272  c0,33.137,26.863,60,60,60h392c33.137,0,60-26.863,60-60V180C512,146.863,485.137,120,452,120z M256,60c33.137,0,60,26.863,60,60  H196C196,86.863,222.863,60,256,60z M402,260v20c0,16.569-13.431,30-30,30h0c-16.569,0-30-13.431-30-30v-20H170v20  c0,16.569-13.431,30-30,30h0c-16.569,0-30-13.431-30-30v-20c-16.569,0-30-13.431-30-30s13.431-30,30-30h292  c16.569,0,30,13.431,30,30S418.569,260,402,260z" />
      </svg>
    )
  },
  {
    section: 'projects-nav',
    svg: (
      <svg x="0px" y="0px" viewBox="0 0 220 220">
        <path
          d="M211.5,15.022C211.5,6.726,204.774,0,196.478,0H23.522C15.226,0,8.5,6.726,8.5,15.022v189.955
C8.5,213.274,15.226,220,23.522,220h172.955c8.297,0,15.022-6.726,15.022-15.022V15.022z M88.5,199h-49v-45h49V199z M88.5,132h-49
V88h49V132z M88.5,66h-49V21h49V66z M144.803,199.63l-26.306-26.306l11.205-11.205l15.101,15.102l23.65-23.65l11.205,11.205
L144.803,199.63z M144.803,133.03l-26.306-26.306l11.205-11.205l15.101,15.101l23.65-23.65l11.205,11.205L144.803,133.03z
M144.803,66.429l-26.306-26.306l11.205-11.205l15.101,15.101l23.65-23.65l11.205,11.205L144.803,66.429z"
        />
      </svg>
    )
  },
  {
    section: 'contact-nav',
    svg: (
      <svg x="0px" y="0px" viewBox="0 0 490 490">
        <g>
          <g>
            <g>
              <path d="M295.2,257.8L251.4,295c-3.5,2.9-8.6,2.9-12,0l-43.8-37.1L16.7,409.1h456.6L295.2,257.8z" />
              <polygon points="0,92.2 0,397.8 180.1,245 			" />
              <polygon points="16.7,80.9 245,274.6 473.3,80.9 			" />
              <polygon points="309.9,245 490,397.8 490,92.2 			" />
            </g>
          </g>
        </g>
      </svg>
    )
  }
]

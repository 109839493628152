import binfo from 'src/assets/images/project4.jpg'
import homestay from 'src/assets/images/project6.png'
import aloline from 'src/assets/images/project7.png'

export const projects = [

  {
    id: '1',
    name: 'Sirena Homestay (Full-stack)',
    isProject: 'My personal project',
    image: homestay,
    teamSize: 1,
    technologies: 'NestJS, Postgres, Docker, Nginx, ReactJS, TailwindCSS, MUI, ...',
    suchAs:
      'RESTful API, NX Library, Auth, Booking, Dashboard for admin...',
    video: '',
    link: 'https://sirena.click/homestay',
    newPrj: true
  },
  {
    id: '2',
    name: 'B-info (Backend)',
    isProject: 'Project at Beta Securities Incorporation.',
    image: binfo,
    teamSize: 4,
    technologies: 'NestJS, MSSQL, Kafka, SOCKET, Redis, Docker, Nginx, PM2...',
    suchAs:
      'RESTful API, NX Library, visualizing data, market analysis, capturing business news...',
    video: '',
    link: 'https://binfo.bsi.com.vn/',
    newPrj: true
  },
  {
    id: '3',
    name: 'Aloline (Backend)',
    isProject: 'Project at Overate-VNTech.',
    image: aloline,
    teamSize: 4,
    technologies: 'NestJS, Cassandra, Postgres, Kafka, SOCKET, Redis, Docker, Nginx, PM2...',
    suchAs:
      'RESTful API, NX Library, visualizing data, market analysis, capturing business news...',
    video: '',
    link: 'https://aloline.vn/',
    newPrj: true
  }

  // {
  //   id: '2',
  //   name: 'Tixket-booking',
  //   isProject: 'Project while learing ReactJS',
  //   image: tixket,
  //   teamSize: 2,
  //   technologies: 'ReactJS, Ant-design, TailwindCSS, Redux, ...',
  //   suchAs: 'Animations, Responsive, Dashboard Admin ...',
  //   video: 'vszKgs1l9DU',
  //   link: 'https://ticket-booking-dccfc.web.app/'
  // },
  // {
  //   id: '3',
  //   name: 'Elearning Course',
  //   isProject: 'Project while learing ReactJS',
  //   image: elearning,
  //   teamSize: 2,
  //   technologies: 'ReactJS, Ant-design, TailwindCSS, Redux, ...',
  //   suchAs: 'Animations, Responsive,  Dashboard Admin...',
  //   video: 'QGn162zs36k',
  //   link: 'https://elearning-course-15664.web.app/'
  // },
  // {
  //   id: '4',
  //   name: 'Shoppe Clone',
  //   isProject: 'Project while learing ReactJS with Typescript',
  //   image: shoppe,
  //   teamSize: 1,
  //   technologies: 'ReactJS with Typescript, i18n,TailwindCSS, Redux, ...',
  //   suchAs: 'Animations, Responsive, Search...',
  //   video: 'QGn162zs36k',
  //   link: 'https://shope-clone.vercel.app/'
  // },

  // {
  //   id: '5',
  //   name: 'Pet-care Landing Page',
  //   isProject: 'The first project when started learning Front-end Developer',
  //   image: petcare,
  //   teamSize: 1,
  //   technologies: 'HTML/SCSS/JS, Bootstrap.',
  //   suchAs: 'Animations, Responsive, ...',
  //   link: 'https://petcare-demo.vercel.app/'
  // },
]
